import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      contact: "Contact me",
      projects: "My projects",
      aboutme: "About me",
      jobtitle: "Software Engineer - Web Developer",
      hello: "Hello,",
      "i am": "I'm Baptiste !",
      resume:
        "I'm a recently graduated engineer in computer science with a Master in Software Development. I'm passionate about web development and I'm currently working as a freelance developer.",
      current: "Current : Freelance Developer",
      specialized: "Specialized in creating modern and responsive websites.",
      2024: "2024 : Full Stack Developer at Marimetrics",
      marimetrics:
        " Software engineer responsible for architecting, developing and verifying software applications for Marimetrics products.",
      2023: "2022-2023 : React Native Developer at Odlamer",
      odlamer:
        "Built a gardening community and smart tools mobile app using React Native.",
      education: "Education",
      MS: "2019-2024 : MS - Computer Science, EFREI Paris",
      Engineer:
        "Specialization in software engineering with a focus on web technologies.",
      Erasmus:
        "Fall 2021 : International engineering program, Technical University of Ostrava, Czech Republic",
      Erasmus_content: "Computer networks, hardware, C, Java, JavaScript.",
      portfolio: "My Portfolio",
      freelance_title: "Freelance Developer",
      freelance_description:
        "Designed and deployed fully functional websites...",
      task_title: "Task Manager",
      task_description: "A Task Manager app using Angular...",
      uno_title: "UNO Project",
      uno_description:
        "Me and my friends made a remake of the famous game, using JavaScript and Node.js",
      blindtest_title: "BlindTest",
      blindtest_description:
        "An online BlindTest to play with friends! Made with React, Tailwind, Node.js, and Socket.io.",
      safevote_title: "Safe Vote",
      safevote_description:
        "A website allowing people to vote online for upcoming elections.",
      bkblog_title: "BK Blog",
      bkblog_description:
        "A Go-based blogging platform that allows users to register, log in, and create posts.",
    },
  },
  fr: {
    translation: {
      contact: "Me contacter",
      projects: "Mes projets",
      aboutme: "A Propos de moi",
      jobtitle: "Ingénieur Logiciel - Développeur Web",
      hello: "Salut,",
      "i am": "Je suis Baptiste !",
      resume:
        "Je suis un ingénieur récemment diplômé en informatique avec un Master en Développement Logiciel. Je suis passionné par le développement web et je travaille actuellement en tant que développeur indépendant.",
      current: "Actuel : Développeur Indépendant",
      specialized: "Spécialisé dans la création de sites modernes.",
      2024: "2024 : Développeur Full Stack chez Marimetrics",
      marimetrics:
        "Ingénieur logiciel responsable de l'architecture, du développement et de la vérification des applications logicielles.",
      2023: "2022-2023 : Développeur React Native chez Odlamer",
      odlamer:
        "Construction d'une communauté de jardinage et d’outils intelligents en utilisant React Native.",
      education: "Éducation",
      MS: "2019-2024 : MS - Informatique, EFREI Paris",
      Engineer:
        "Spécialisation en ingénierie logicielle avec un focus sur les technologies web.",
      Erasmus:
        "Automne 2021 : Programme d'ingénierie international, Université technique d'Ostrava, République tchèque",
      Erasmus_content: "Réseaux informatiques, C, Java, JavaScript.",
      portfolio: "Mon Portfolio",
      freelance_title: "Développeur Freelance",
      freelance_description:
        "Conception et déploiement de sites web entièrement fonctionnels...",
      task_title: "Gestionnaire de Tâches",
      task_description:
        "Une application de gestion de tâches développée avec Angular...",
      uno_title: "Projet UNO",
      uno_description:
        "Avec des amis, nous avons recréé le célèbre jeu en utilisant JavaScript et Node.js",
      blindtest_title: "BlindTest",
      blindtest_description:
        "Un BlindTest en ligne pour jouer entre amis ! Développé avec React, Tailwind, Node.js et Socket.io.",
      safevote_title: "Safe Vote",
      safevote_description:
        "Un site web permettant de voter en ligne pour les prochaines élections.",
      bkblog_title: "BK Blog",
      bkblog_description:
        "Une plateforme de blog basée sur Go permettant aux utilisateurs de s'inscrire, se connecter et créer des articles.",
    },
  },
};

i18n
  .use(LanguageDetector) // Détecte la langue du navigateur
  .use(initReactI18next) // Initie React-i18next
  .init({
    resources,
    fallbackLng: "fr", // Langue par défaut
    interpolation: {
      escapeValue: false, // React gère automatiquement les XSS
    },
  });

export default i18n;
