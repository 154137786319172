import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const { t } = useTranslation();

  return (
    <section id="aboutme" className="AboutSection">
      <motion.div
        className="aboutme"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.3 }}
        viewport={{ once: true }}
      >
        <div className="container_Top_about">
          <div className="container_presentation">
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >
              {t("hello")}
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.1 }}
              viewport={{ once: true }}
            >
              {t("i am")}
            </motion.h1>
            <br />
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
              viewport={{ once: true }}
            >
              {t("resume")}
            </motion.p>
            <a href="https://www.linkedin.com/in/baptiste-keunebroek-95a2ab194/">
              <motion.button
                type="button"
                className="contact_linkedin"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                viewport={{ once: true }}
              >
                linkedin.com/in/baptiste-keunebroek
              </motion.button>
            </a>
          </div>
        </div>

        <div className="container_Middle_about">
          <div className="experience">
            <motion.h1
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >
              Experience
            </motion.h1>
            <motion.ul
              className="odlaMer"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
              viewport={{ once: true }}
            >
              <li>{t("current")}</li>
              <p>{t("specialized")}</p>
            </motion.ul>
            <motion.ul
              className="odlaMer"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              viewport={{ once: true }}
            >
              <li>{t("2024")}</li>
              <p>{t("marimetrics")}</p>
            </motion.ul>
            <motion.ul
              className="odlaMer"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.7 }}
              viewport={{ once: true }}
            >
              <li>{t("2023")}</li>
              <p>{t("odlamer")}</p>
            </motion.ul>
          </div>

          <motion.div
            className="education"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
            viewport={{ once: true }}
          >
            <motion.h1
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >
              {t("education")}
            </motion.h1>
            <motion.ul
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              viewport={{ once: true }}
            >
              <li>{t("MS")}</li>
              <p>{t("Engineer")}</p>
            </motion.ul>
            <motion.ul
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              viewport={{ once: true }}
            >
              <li>{t("Erasmus")}</li>
              <p>{t("Erasmus_content")}</p>
            </motion.ul>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default AboutSection;
