import { React, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { SiMalt, SiLinkedin } from "react-icons/si";

import ProfileImage from "../assets/Baptiste_Keunebroek_Banner.jpg";

import "../style/Home.css";

import AboutSection from "../components/AboutSection";
import ProjectSection from "../components/ProjectSection";

const Home = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [, setScreenWidth] = useState(window.innerWidth);
  const [, setActiveSection] = useState("home");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setActiveSection(id);
    }
  };

  return (
    <div className="portofolio">
      <section id="home" className="HomeSection">
        <div className="HomePage">
          <div className="ProfileImage">
            <motion.img
              src={ProfileImage}
              alt="Baptiste Keunebroek"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, ease: "easeOut" }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.2 }}
              loading="lazy"
            />

            <motion.div
              className="ProfilePresentation"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <motion.h1
                className="ProfileText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.4 }}
              >
                Baptiste Keunebroek
              </motion.h1>
              <motion.h2
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
              >
                {t("jobtitle")}
              </motion.h2>
              <motion.h3
                className="ProfileText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.8 }}
              >
                <button
                  className="ProfileButton"
                  onClick={() => scrollToSection("aboutme")}
                >
                  {t("aboutme")}
                </button>
              </motion.h3>
              <motion.h3
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.8 }}
              >
                <div className="social_icons">
                  <a
                    href="https://www.linkedin.com/in/baptiste-keunebroek-95a2ab194/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ProfileIconLink"
                  >
                    <SiLinkedin size={30} className="ProfileIcon" />
                  </a>
                  <a
                    href="https://www.malt.fr/profile/baptistekeunebroek"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ProfileIconLink"
                  >
                    <SiMalt size={50} className="ProfileIcon" />
                  </a>
                </div>
              </motion.h3>
            </motion.div>
          </div>
        </div>
      </section>
      <AboutSection />
      <ProjectSection />
    </div>
  );
};

export default Home;
