import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";

import LibrairieImage from "../assets/Baptiste_Keunebroek_librairie.PNG";
import TaskManagerImage from "../assets/Baptiste_Keunebroek_Task_Manager.png";
import UnoImage from "../assets/Baptiste_Keunebroek_Uno-logo.png";
import BlindTestImage from "../assets/Baptiste_Keunebroek_blindtest_logo.jpeg";
import SafeVoteImage from "../assets/Baptiste_Keunebroek_safe_vote_logo.png";
import BkBlogImage from "../assets/Baptiste_Keunebroek_BK_Blog_logo.png";

const ProjectSection = () => {
  const { t } = useTranslation();

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  };

  const projects = [
    {
      title: t("freelance_title"),
      description: t("freelance_description"),
      className: "librairie",
      image: LibrairieImage,
      link: "https://librairie-saint-nicolas.com/",
    },
    {
      title: t("task_title"),
      description: t("task_description"),
      className: "Project_Task_Manager",
      image: TaskManagerImage,
      link: "https://github.com/Baptistekeunbroek/task-tracker/",
    },
    {
      title: t("uno_title"),
      description: t("uno_description"),
      className: "Project_UNO",
      image: UnoImage,

      link: "https://github.com/VengaGames/Uno",
    },
    {
      title: t("blindtest_title"),
      description: t("blindtest_description"),
      className: "Project_BlindTest",
      image: BlindTestImage,

      link: "https://github.com/Malo-LC/BlindTest",
    },
    {
      title: t("safevote_title"),
      description: t("safevote_description"),
      className: "Project_Safe_Vote",
      image: SafeVoteImage,

      link: "https://github.com/Baptistekeunbroek/SafeVote",
    },
    {
      title: t("bkblog_title"),
      description: t("bkblog_description"),
      className: "Project_BK_Blog",
      image: BkBlogImage,

      link: "https://github.com/Baptistekeunbroek/go_blog",
    },
  ];

  return (
    <section id="projects" className="HomeSection">
      <div className="projects_page">
        <div className="projects-title">
          <h1>{t("portfolio")}</h1>
        </div>

        <Slider {...settings}>
          {projects.map((project) => (
            <div className="project_slide" key={project.id}>
              <NavLink
                to={project.link}
                target="_blank"
                className="project-link"
              >
                <img
                  src={project.image}
                  alt={project.title}
                  className="project-image"
                  loading="lazy"
                />
              </NavLink>
              <div className="project-content">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ProjectSection;
